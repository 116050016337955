.CartsCss {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 2fr .5fr;
    grid-template-areas: "blank CartsCss1"". CartsCss5";
    grid-gap: .2rem;
    justify-content: center;
    margin: 2%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F4F4F4;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
    font-size: smaller;
    align-items: center;
}

.blank {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 80%;
}

.CartsCss .CartsCss1 {
    grid-area: CartsCss1;
    display: flex;
}
.CartsCss5 {
    grid-area: CartsCss5;
    font : Montserrat;
    font-weight:900;
    letter-spacing: 0px;
    color: #080808;
    opacity: 1;
    text-align:right;
}   
