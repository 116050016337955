.ProfileWrapper {
    margin: 2%;
    padding: 2%;
    display: flex;
    flex-direction: row;
}

.DisplayPhoto {
    width: 260px;
    height: 209px;
    opacity: 1;
}

.mainContent {
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 2px solid black;
    width: 100%;
}

.row1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.row3 .row4 {
    display: flex;
    flex-direction: column;
}

.row3>div {
    margin: 2%;
}

.row4>div {
    margin: 2%;
}

.row5 {
    display: flex;
    flex-direction: column;
}

#experience {
    display: flex;
    flex-direction: row;
}

#experience>div {
    margin-right: 4%;
}

.row6 {
    display: flex;
    flex-direction: column;
}

#expertise {
    display: flex;
    flex-direction: row;
}

#expertise>div {
    margin-right: 4%;
}

.data {
    border: 1px solid #E0E0E0;
    opacity: 1;
    padding: 1%;
}

#TextField1 {
    grid-area: TextField1;
    display: flex;
    flex-direction: row;
    background: #F6F8FF 0% 0% no-repeat padding-box;
    opacity: 1;
}

#TextField2 {
    grid-area: TextField2;
    display: flex;
    flex-direction: row;
    background: #F6F8FF 0% 0% no-repeat padding-box;
    opacity: 1;
}

#TextField3 {
    grid-area: TextField3;
    display: flex;
    flex-direction: row;
    background: #F6F8FF 0% 0% no-repeat padding-box;
    opacity: 1;
}

#TextField4 {
    grid-area: TextField3;
    display: flex;
    flex-direction: row;
    width: 66%;
    height: 7rem;
    background: #F6F8FF 0% 0% no-repeat padding-box;
    opacity: 1;
}
#TextField5{
    background: #F6F8FF 0% 0% no-repeat padding-box;
    opacity: 1;
    width : 30%;
}
#btn{
    background-color: #FF6738;
    width: min-content;
    margin : 2%;
    color:white;

}
.error{
    color: red;
}