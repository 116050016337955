.WishListWrapper {
    margin: 1%;
    padding: 1%;
    display: flex;
    flex-direction: row;
}

.WishList {
    flex: 3;
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
}

.WishListCartDetails {
    flex: 1;
    text-align: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
}

.WishListCardsCss {
    display: grid;
    grid-template-columns: .6fr 2fr .9fr .2fr .4fr .6fr .2fr .1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "blank WishListCardsCss1 WishListCardsCss4 WishListCardsCss5 WishListCardsCss6 WishListCardsCss7 WishListCardsCss8 WishListCardsCss9";
    grid-gap: .2rem;
    justify-content: space-between;
    margin: 1%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F4F4F4;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
    font-size: smaller;
    align-items: center;
}

.blank {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 80%;
}

.WishListCardsCss .WishListCardsCss1 {
    grid-area: WishListCardsCss1;
    display: flex;
    flex-direction: column;
}

#course-title1 {
    overflow: hidden;
}

#tag2 {
    width: 10%;
    background-color: #e65022;
    border-radius: 4px;
    padding-left: 1em;
    padding-right: 1em;
    font-size: .7em;
    color: #FFFFFF;
    margin-right: 1em;
}

.WishListCardsCss3 {
    grid-area: WishListCardsCss3;
}

.WishListCardsCss4 {
    grid-area: WishListCardsCss4;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    color: #615f5f;
}

.WishListCardsCss5 {
    grid-area: WishListCardsCss5;
    font: Montserrat;
    font-weight: 900;
    letter-spacing: 0px;
    color: #080808;
    opacity: 1;
}

.WishListCardsCss6 {
    grid-area: WishListCardsCss6;
}

.WishListCardsCss7 {
    grid-area: WishListCardsCss7;
    background: #e65022 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    max-height: 40%;
}

.WishListCardsCss8 {
    grid-area: WishListCardsCss8;
}

.WishListCardsCss9 {
    grid-area: WishListCardsCss9;
}
.text1{
    text-align: left;
    margin : 4%;
}