body {
  margin: 0;
  font: normal normal 300 16px/19px Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
  display: flex;
  /* border : 2px solid red; */
  flex-direction: row;
  justify-content: space-between;
  background-color: #F5F5F5;
}

header .navSecondColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 2%;
  align-items: center;
}

.navSecondColumn>div {
  margin: 2%;
  font-size: small;
  margin-left: 5%;
  align-items: center;
}

section {
  display: grid;
  width : 95%;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr auto auto;
  grid-template-areas: "Ad Ad" "main asideDetail" "main asideDetail";
  justify-content: center;
  /* align-items: center; */
}

section .Ad {
  display: flex;
  flex-direction: row;
  grid-area: Ad;
}
.CountPrice{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.CountPrice .Checkout{
  align-items: center;
  color: orangered;
  font-size:xx-small;
  /* margin : 2%; */
  position: relative;
  top : 20px;
  left : 30px;
}

section main {
  grid-area: main;
}

section asideDetail {
  grid-area: asideDetail;
}

section .main {
  display: flex;
  flex-direction: column;
  margin: 2%;
}

section .main .main1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

section .main .main1>div {
  margin-left: 3%;
  font-weight: bold;
}

section .main .main1 .main2 {
  margin-right: 3%;
}

.asideDetail .searchBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5%;
}

.CartDetails {
  display: flex;
  flex-direction: column;
  margin: 2%;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
.Ad .bottomLeft{
    background-color: black;
}
.modalBackground{
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: opasity 0.4s ease-in-out;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  margin : auto;
}
.modalShowing-true{
  opacity: 1;
  pointer-events: visible;
}
a.wishlist{
  text-decoration: none;
  color : black;
}