.CheckoutWrapper {
    margin-left: 3%;
    margin-right: 3%;
    /* align-items: center; */
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "CheckoutList CheckoutAside";
    justify-content: center;
}

.CheckoutWrapper .CheckoutList {
    grid-area: CheckoutList;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}
.CheckoutAside {
    grid-area: CheckoutAside;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E0E0E0;
    opacity: 1;
    margin : 5%;
    padding: 10%;
}
#text1 {
    margin : 5%;
}
#text2 {
    margin : 5%;
}
#text3 {
    color: chartreuse;
    margin : 5%;;
}

#CheckoutAsideButton {
    background: orangered 0% 0% no-repeat padding-box;
    opacity: 1;
    margin : 5%;
}

.CheckOutCardsCss {
    display: grid;
    grid-template-columns: .5fr 2fr repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "blanks course-title author-name CheckoutCardsCss3 CheckoutCardsCss4 CheckoutCardsCss5";
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F4F4F4;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
    margin: 1%;
    font-size: smaller;
    align-items: center;
    grid-gap: .2rem;
    justify-content: center;
}

.CheckoutCardsCss .blanks {
    grid-area: blanks;
    background: #838181 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 70%;
    width: 70%;
}

#course-title {
    grid-area: course-title;
    overflow: hidden;
}

#author-name {
    grid-area: author-name;
}

.CheckoutCardsCss .CheckoutCardsCss3 {
    grid-area: CheckoutCardsCss3;
}

.CheckoutCardsCss .CheckoutCardsCss4 {
    grid-area: CheckoutCardsCss4;
}

.CheckoutCardsCss .CheckoutCardsCss5 {
    grid-area: CheckoutCardsCss5;
    size: 50%;
}


.WCardCss {
    display: grid;
    grid-template-columns: .8fr 3fr 1fr .1fr .5fr .5fr .8fr;
    grid-template-rows: 1fr;
    grid-template-areas: "Wblank WCardsCss1 WCardsCss4 WCardsCss9 WCardsCss5 WCardsCss6 WCardsCss7 WCardsCss8";
    grid-gap: .2rem;
    justify-content: space-between;
    margin: 1%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F4F4F4;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
    font-size: smaller;
    align-items: center;
}

.Wblank {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 80%;
}

.WCardsCss .WCardsCss1 {
    grid-area: WCardsCss1;
    display: flex;
    flex-direction: column;
}
#Wcourse-title{
    overflow: hidden;
}
#Wtag1{
    width: 10%;
    background-color: #e65022;
    border-radius: 4px;
    padding-left: 1em;
    padding-right: 1em;
    font-size: .7em;
    color:#FFFFFF;
    margin-right: 1em;
}

.WCardsCss3 {
    grid-area: WCardsCss3;
}

.WCardsCss4 {
    grid-area: WCardsCss4;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    color: #615f5f;
}

.WCardsCss5 {
    grid-area: WCardsCss5;
    font : Montserrat;
    font-weight:900;
    letter-spacing: 0px;
    color: #080808;
    opacity: 1;
}

.WCardsCss6 {
    grid-area: WCardsCss6;
}

.WCardsCss7 {
    grid-area: WCardsCss7;
    background: #e65022 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    max-height: 40%;
}

.WCardsCss8 {
    grid-area: WCardsCss8;
}
.WCardsCss9 {
    grid-area: WCardsCss9;
    border-radius: 0%;
    border-style: none;
    background-color: white;
}