.CardsCss {
    display: grid;
    grid-template-columns: .8fr 3fr 1fr .1fr .5fr .5fr .8fr;
    grid-template-rows: 1fr;
    grid-template-areas: "blank CardsCss1 CardsCss4 CardsCss9 CardsCss5 CardsCss6 CardsCss7 CardsCss8";
    grid-gap: .2rem;
    justify-content: space-between;
    margin: 1%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F4F4F4;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
    font-size: smaller;
    align-items: center;
}

.blank {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 80%;
}

.CardsCss .CardsCss1 {
    grid-area: CardsCss1;
    display: flex;
    flex-direction: column;
}
#course-title{
    overflow: hidden;
}
#tag1{
    width: 10%;
    background-color: #e65022;
    border-radius: 4px;
    padding-left: 1em;
    padding-right: 1em;
    font-size: .7em;
    color:#FFFFFF;
    margin-right: 1em;
}

.CardsCss3 {
    grid-area: CardsCss3;
}

.CardsCss4 {
    grid-area: CardsCss4;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    color: #615f5f;
}

.CardsCss5 {
    grid-area: CardsCss5;
    font : Montserrat;
    font-weight:900;
    letter-spacing: 0px;
    color: #080808;
    opacity: 1;
}

.CardsCss6 {
    grid-area: CardsCss6;
}

.CardsCss7 {
    grid-area: CardsCss7;
    background: #e65022 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    height: max-content;
    width: max-content;
}

.CardsCss8 {
    grid-area: CardsCss8;
}
.CardsCss9 {
    grid-area: CardsCss9;
    border-radius: 0%;
    border-style: none;
    background-color: white;
}